.card-styles {
  border: 1px $light-gray;
  border-radius: $border-radius-small;
}

.el-picker-panel.time-select.el-popper {
  @extend .card-styles;
}

.el-picker-panel.el-date-picker {
  width: 300px;
  border: 0;
  @extend .card-styles;
  .el-date-picker__header,
  .el-picker-panel__content {
    margin: 5px;
  }
}

.el-date-picker__header,
.el-date-range-picker__header {
  &.el-date-picker__header--bordered {
    border: none;
  }

  .el-picker-panel__icon-btn {
    margin-top: 0;
    height: 27px;
    width: 27px;
    color: $primary-color;

    &:hover {
      border-radius: 50%;
      background-color: #eee;
    }
  }
  .el-date-picker__header-label, div {
    font-size: $font-size-base;
    font-weight: $font-weight-light;
    color: $primary-color;
    &:hover {
      background-color: #eee;
      border-radius: $border-radius-large;
    }
  }
}
.el-picker-panel .el-picker-panel__shortcut:hover{
  color: $primary-color;
}
.el-picker-panel__content {
  .el-date-table th {
    color: $primary-color;
    font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
    font-size: $font-size-base;
    font-weight: $font-weight-light;
    border: none;
  }
  .el-date-table {
    td.current:not(.disabled) span {
      background-color: $primary-color;
    }
    td.today span{
      color: $primary-color;
    }
  }
}

.el-picker-panel__content .el-date-table td,
.el-picker-panel__content .el-month-table td,
.el-picker-panel__content .el-year-table td {
  height: 32px;
  width: 32px;
  padding: 0;
  &.current span,
  &.current a.cell {
    background-color: $primary-color;
    color: $white-color;
    border-radius: 50%;
  }
  div {
    &:hover {
      color: $default-color
    }
    height: 100%;
    padding: 0;
  }

  .cell,
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $font-size-base;

    &:hover {
      border-radius: 50%;
      background: #eee;
      cursor: pointer;
      color: $default-color;
    }
  }
  span {
    width: 32px;
    height: 32px;
  }
  .cell {
    width: 40px;
    height: 40px;
  }
}

.el-date-picker .el-date-picker__time-header {
  border: none;
}

.el-date-picker .el-time-panel.el-popper {
  @extend .card-styles;
  button {
    @extend .btn-primary, .btn-outline, .btn-sm;
    padding: 7px !important;
    margin: 0 5px 0 0;
    line-height: 14px;
    font-weight: $font-weight-normal;
    &:first-child {
      @extend .btn-danger;
    }
  }
}

.el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: 100% !important;
}

.el-date-editor.el-input .el-input__inner {
  padding-left: 30px !important
}

.el-date-picker .el-input {
  .el-input__inner {
    @extend .form-control;
  }
}

.el-date-range-picker .el-picker-panel__footer,
.el-date-picker .el-picker-panel__footer {
  .el-button {
    @extend .btn-primary, .btn-outline, .btn-sm;
    &:first-child {
      @extend .btn-info, .btn-outline;
    }
  }
}

// Time picker
.el-time-spinner.has-seconds .el-time-spinner__wrapper:nth-child(2){
  margin-left: 0 !important;
}
.el-picker-panel .time-select-item.selected:not(.disabled){
  color: $primary-color;
}
